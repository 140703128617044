import * as React from "react";
import Layout from "../components/layout/layout";
import Hero from "../sections/hero/hero";
import Image from "../images/for-developers-hero.jpg";

const CareersPage = () => {
  return (
    <Layout pageTitle="Careers - Lossless">
      <Hero
        title="Open Positions at Lossless"
        text="We welcome people from all backgrounds who seek the opportunity to help build a future where crypto hacks are stopped and reverted. "
        backgroundUrl={Image}
        titleMaxWidth="61rem"
      />
    </Layout>
  );
};

export default CareersPage;
