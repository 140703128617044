import React, { useState, useEffect } from "react";
import * as styles from "./hero.module.scss";
import Container from "../../components/container/container";
import parse from "html-react-parser";
import cn from "classnames";

const Hero = ({
  title,
  text,
  backgroundUrl,
  titleMaxWidth,
  textMaxWidth,
  video,
  align,
  margin,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <section
      className={cn(styles.hero)}
      style={{
        backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : null,
      }}
    >
      {video && (
        <video
          autoPlay
          muted
          loop
          playsInline
          className={cn(isLoaded && styles.heroIsActive)}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}

      <Container>
        <div>
          <h1
            style={{
              maxWidth: titleMaxWidth,
              textAlign: align,
              margin: margin,
            }}
          >
            {title}
          </h1>
          {text && (
            <p
              style={{
                maxWidth: textMaxWidth,
                textAlign: align,
                marginLeft: margin && "auto",
                marginRight: margin && "auto",
              }}
            >
              {parse(text)}
            </p>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Hero;
